<script setup>
import {computed} from "vue";
import {BUTTON_KINDS} from "@Res/js/constants";

const props = defineProps({
    type: {
        type: String,
        default: "button",
    },
    processing: {
        type: Boolean,
        default: false,
    },
    fit: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: "md",
        validator(value) {
            return ["xs", "sm", "md", "lg", "xl"].includes(value);
        },
    },
    showSpinner: {
        type: Boolean,
        default: true,
    },
    spinnerPosition: {
        type: String,
        default: null,
        validator: value => ["left", "center", "right"].includes(value)
    },
    kind: {
        type: String,
        default: "primary",
        validator: kind => BUTTON_KINDS.includes(kind),
    },
});

const mainType = computed(() => (props.type === "link" ? "link" : "button"));
</script>

<template>
    <button
        :class="[
            `${mainType} ${mainType}-${kind}`,
            size ? `${mainType}-${size}` : '',
            { 'w-fit': fit },
            { 'button--is-processing': processing },
        ]"
        :disabled="processing"
        :type="type === 'link' ? 'button' : type"
    >
        <svg v-if="$slots.default && processing && showSpinner"
             :class="['size-6 button-processing-spinner-icon', `processing-icon-${spinnerPosition}`]">
            <use href="#icon-spinner"/>
        </svg>

        <slot/>
    </button>
</template>
